import React from 'react'
import Link from 'gatsby-link'
import * as BlogStyles from '../../styles'
import kebabCase from 'lodash/kebabCase'
import { useStaticQuery, graphql } from 'gatsby'

const Tag = ({ tag, totalCount }) => (
  <BlogStyles.Tag className={['tag', tag].join(' ')}>
    {tag} {totalCount && `- ${totalCount}`}
  </BlogStyles.Tag>
)

const Tags = ({ tags, includeAllTags }) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        group(field: frontmatter___tags) {
          tag: fieldValue
          totalCount
        }
      }
    }
  `)

  if (!tags) {
    tags = data.allMarkdownRemark.group
  }

  return (
    <BlogStyles.TagsSection>
      {includeAllTags && (
        <Link className="link" to="/">
          <Tag className="all-tags" tag="All" />
        </Link>
      )}
      {tags.map(({ tag, totalCount }) => (
        <Link className="link" to={`/tags/${kebabCase(tag)}/`}>
          <Tag className={tag} tag={tag} totalCount={totalCount} />
        </Link>
      ))}
    </BlogStyles.TagsSection>
  )
}

export { Tag, Tags }
