import React from 'react'
import TextTruncate from 'react-text-truncate'
import { navigate } from 'gatsby'
import * as styles from '../../styles/blogpost'
import { Tags } from '../Tags'

export default ({ edges }) => {
  return edges.map(({ node }) => {
    const { frontmatter } = node
    const title = frontmatter.title || node.fields.slug
    const { path, tags, description, date } = frontmatter

    const parsedTags = tags.map(tag => ({ tag }))

    return (
      <styles.PostLinkSection key={title} onClick={() => navigate(path)}>
        <div className="description">
          <h4 className="title">{title}</h4>
          <TextTruncate line={1} truncateText="…" text={description} />
        </div>
        <div className="dates">
          <p>{date}</p>

          <Tags tags={parsedTags} />
        </div>
      </styles.PostLinkSection>
    )
  })
}
