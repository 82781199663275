import React from 'react'
import Layout from '../layout'
import SEO from '../seo'
import * as styles from '../../styles'
import { Tags } from '../Tags'
import SharedContent from './SharedContent'

export default props => {
  const {
    data: { site, allMarkdownRemark },
  } = props

  const {
    siteMetadata: { title: siteTitle },
  } = site

  const { edges, group: tags } = allMarkdownRemark

  return (
    <Layout>
      <SEO title={siteTitle} />

      <styles.PostWrapper>
        <styles.LeftSide>
          <Tags includeAllTags />
        </styles.LeftSide>

        <styles.RightSide>
          <SharedContent edges={edges} />
        </styles.RightSide>
      </styles.PostWrapper>
    </Layout>
  )
}
